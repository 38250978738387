import { RouterView } from "vue-router";
import Tr from "../i18n/translation";
import { companiesRoutes } from "./companiesRoutes.js";
import { productRoutes } from "./productRoutes.js";

// sitemap
import {
  getNewsCount,
  getNews,
  getEmployees,
  getDivisions,
} from "../helpers/getDynamicRoutes.js";

export const routes = [
  {
    path: "/:locale?",
    beforeEnter: Tr.routeMiddleware,
    component: RouterView,
    meta: {
      sitemap: {
        slugs: ["ru"],
      },
    },
    children: [
      {
        path: "",
        component: RouterView,
        children: [
          {
            path: "",
            name: "Home",
            component: () => import("@/views/HomePage.vue"),
          },
        ],
      },
      ...productRoutes,
      {
        path: "news",
        component: RouterView,
        children: [
          {
            path: "",
            name: "news",
            component: () => import("@/views/News/NewsPage.vue"),
            // meta: {
            //   sitemap: {
            //     slugs: async () => getNewsCount(),
            //   },
            // },
          },
          {
            path: ":slug",
            name: "news-item",
            component: () => import("@/views/News/NewsItemPage.vue"),
            meta: {
              sitemap: {
                slugs: async () => getNews("gk_konar"),
              },
            },
          },
        ],
      },
      {
        path: "studying",
        component: RouterView,
        children: [
          {
            path: "law-base",
            name: "studying/law-base",
            component: () => import("@/views/Studying/LawBase.vue"),
          },
          {
            path: "partners",
            name: "studying/partners",
            component: () => import("@/views/Studying/PartnersPage.vue"),
          },
          {
            path: "basic-info",
            name: "studying/basic-info",
            component: () => import("@/views/Studying/BasicInfoPage.vue"),
          },
          {
            path: "education",
            name: "studying/education",
            component: () => import("@/views/Studying/EducationPage.vue"),
          },
          {
            path: "scholarship",
            name: "studying/scholarship",
            component: () => import("@/views/Studying/ScholarshipPage.vue"),
          },
          // {
          //   path: "vacancies",
          //   name: "studying/vacancies",
          //   component: () => import("@/views/Studying/VacanciesPage.vue"),
          // },
          // {
          //   path: "financial",
          //   name: "studying/financial",
          //   component: () => import("@/views/Studying/FinancialPage.vue"),
          // },
          {
            path: "courses",
            name: "studying/courses",
            component: () => import("@/views/Studying/CoursesPage.vue"),
          },
          // {
          //   path: "edu-standards",
          //   name: "studying/edu-standards",
          //   component: () => import("@/views/Studying/EduStandardsPage.vue"),
          // },
          {
            path: "docs",
            name: "studying/docs",
            component: () => import("@/views/Studying/DocsPage.vue"),
          },
          {
            path: "resources",
            name: "studying/resources",
            component: () => import("@/views/Studying/ResourcesPage.vue"),
          },
          {
            path: "exam",
            name: "studying/exam",
            component: () => import("@/views/Studying/ExamPage.vue"),
          },
          {
            path: "qualification-assessment-center",
            name: "studying/qualification-assessment-center",
            component: () => import("@/views/Studying/CentreQualify.vue"),
          },
          {
            path: "qualifications-for-assessment",
            name: "studying/qualifications-for-assessment",
            component: () => import("@/views/Studying/TheQualifications.vue"),
          },
        ],
      },
      {
        path: "carrier",
        component: RouterView,
        children: [
          {
            path: ":employee",
            name: "carrier/employee",
            component: () => import("@/views/templates/EmployeePage.vue"),
            meta: {
              sitemap: {
                slugs: async () => getEmployees(),
              },
            },
          },
          {
            path: "for-students",
            name: "carrier/for-students",
            component: () => import("@/views/Studying/ForStudentsPage.vue"),
          },
        ],
      },
      {
        path: "company",
        component: RouterView,
        children: [
          {
            path: ":division",
            name: "company/division",
            component: () => import("@/views/templates/DivisionPage.vue"),
            meta: {
              sitemap: {
                slugs: async () => getDivisions(),
              },
            },
          },
          // {
          //   path: "test",
          //   name: "test",
          //   component: () => import("@/views/TestPage.vue"),
          // },
          {
            path: "about-company",
            name: "company/about-company",
            component: () => import("@/views/Company/AboutCompanyPage.vue"),
          },
          {
            path: "policy-quality",
            name: "company/policy-quality",
            component: () => import("@/views/Company/PolicyQualityPage.vue"),
          },
          {
            path: "photo-gallery",
            name: "company/photo-gallery",
            component: () => import("@/views/Company/PhotoGalleryPage.vue"),
          },
          {
            path: "video-gallery",
            name: "company/video-gallery",
            component: () => import("@/views/Company/VideoGalleryPage.vue"),
          },
        ],
      },
      {
        path: "contacts",
        name: "contacts",
        component: () =>
          import("@/views/templates/companies/CompanyContacts.vue"),
      },
      {
        path: "certificates",
        name: "serts",
        component: () => import("@/views/Company/SertsCompanyPage.vue"),
      },
      {
        path: "personal-data",
        name: "personal-data",
        component: () => import("@/views/PersonalData.vue"),
      },
      {
        path: "search",
        name: "search-page",
        component: () => import("@/views/SearchPage.vue"),
      },
      ...companiesRoutes,
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "error",
    component: () => import("@/views/ErrorPage.vue"),
  },
  // {
  //   path: ":pathMatch(.*)*",
  //   redirect: "/404",
  // },
];
